<template>
  <div
    title="usePolicyEn"
    style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll"
    align="center"
    overflow="scroll"
  >
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div>
        <h1 />
        <p align="left" />
        <p align="left" />
        <p align="left" />
        <p align="left" />
        <p align="left" />
        <p align="left" />
        <p align="left" />
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 26px">Acceptable Use Policy </p>
          </h3>
        </div>
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 20px">{{ themeData.saasMerNameEn }} Limited</p>
          </h3>
        </div>
        <div style="margin-top: 20px">
          <h3>
            <p style="font-size: 20px">May 2021 </p>
          </h3>
        </div>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <h1 style="page-break-before:always; " />
        <p align="left">This acceptable use policy sets out the terms under which you may access our Services. </p>
        <p align="left">It applies as soon as you access and/or use our Services. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>1. What you cannot do </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">You may use our Services only for lawful purposes. You may not use our Services: </p>
        <p align="left" />
        <p align="left">1. in any way that breaches any applicable local, national or international law or regulation or causes {{ themeData.saasMerNameEn }} to breach any applicable law or regulation; </p>
        <p align="left" />
        <p align="left">2. in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; </p>
        <p align="left" />
        <p align="left">3. for the purpose of harming or attempting to harm minors in any way; </p>
        <p align="left" />
        <p align="left">4. for anything that is abusive or does not comply with our content standards; </p>
        <p align="left" />
        <p align="left">5. for any unsolicited or unauthorised advertising or promotional material or any other form of spam; </p>
        <p align="left" />
        <p align="left">6. to deal in harmful programs like viruses or spyware or similar computer code designed to adversely affect the operation of any computer software or hardware; or </p>
        <p align="left">7. in any way that would locally or internationally evade any applicable taxes or facilitate tax evasion. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b /></p>
        <p class="navigationTitle" align="left"><b>2. We do not provide our Services to businesses or support transactions which involve: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">1. casino’s, lotteries, online gambling or any action which involves a stake of an asset with a view to winning a prize/reward; </p>
        <p align="left">2. tradeable and/or redeemable virtual currencies; </p>
        <p align="left">3. adult entertainment and services, including pornography, dating, escort services and sale and/or advertising of sexual services; </p>
        <p align="left">4. arms and weapons manufacturers, suppliers or dealer; </p>
        <p align="left">5. unregulated money service businesses, finance or exchange houses. 6. FX speculation; </p>
        <p align="left">7. precious metals and stones; </p>
        <p align="left">8. private-to-private money remittance involving cash; </p>
        <p align="left">9. bidding fee auctions; </p>
        <p align="left">10. secondhand vehicles; </p>
        <p align="left">11. dangerous or hazardous biological, chemical or nuclear materials; </p>
        <p align="left">12. prescription drugs and devices in violation of applicable laws or industry regulations; </p>
        <p align="left">13. products or services identified by government agencies of being fraudulent; </p>
        <p align="left">14. pyramid schemes or certain multi-level marketing programs; </p>
        <p align="left">15. the abuse of animals, vivisection or the use of animals for any scientific testing purpose; </p>
        <p align="left">16. retail establishment on Massage Parlors, Nail Shops/Nail Salons, night clubs, Places of religious worship, sauna, hammam, smoking clubs, sport gambling; </p>
        <p align="left">17. embassies, diplomatic Missions and consulates </p>
        <p align="left">18. other businesses or transactions outside of our risk appetite in accordance with our internal policies, our banking partners’ policies or the policies of participants in our payment network, including but not limited to below aspects: </p>
        <p align="left" class="use-two-title">(i) violate any law, statute, ordinance or regulation; </p>
        <p align="left" class="use-two-title">(ii) engage in illegal activity; </p>
        <p align="left" class="use-two-title">(iii) financial exploitation of a crime; </p>
        <p align="left" class="use-two-title">(iv) stolen goods; </p>
        <p align="left" class="use-two-title">(v) drug paraphernalia; </p>
        <p align="left" class="use-two-title">(vi) considered obscene; </p>
        <h1 style="page-break-before:always; " />
        <p align="left" class="use-two-title">(vii) sexually oriented materials or services; </p>
        <p align="left" class="use-two-title">(viii) human or animal organs, including blood and plasma; </p>
        <p align="left" class="use-two-title">(ix) violate any copyright or trademark; </p>
        <p align="left" class="use-two-title">(x) violate right of publicity or privacy; </p>
        <p align="left" class="use-two-title">(xi) show personal information of third parties in violation of applicable law; </p>
        <p align="left" class="use-two-title">(xii) adoption agencies, including surrogate motherhood; </p>
        <p align="left" class="use-two-title">(xiii) ammunition, firearms, certain firearm parts or accessories; </p>
        <p align="left" class="use-two-title">(xiv) certain weapons or knives regulated under applicable law; </p>
        <p align="left" class="use-two-title">(xv) mercenary or contract soldiering; </p>
        <p align="left" class="use-two-title">(xvi) the abuse of refugees or human rights; </p>
        <p align="left" class="use-two-title">(xvii) the abuse of animals, vivisection or the use of animals for any scientific testing purpose; </p>
        <p align="left" />
        <p align="left" />
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>3. UNLESS with special approval given by our Management Team and upon completion with satisfactory result of Enhanced Due Diligence, we will NOT provide our Services to businesses or support transactions which involve: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left">1. Games and anchor/ Dating apps offered Google play or Appstore; </p>
        <p align="left">2. Crypto related business, such as mining rigs, exchange, DeFi or others </p>
        <p align="left">3. Companies involved in Commodities supply; </p>
        <p align="left">4. Investment Companies </p>
        <p align="left" />
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>4. We do not provide our Services to the following categories of entities: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">1. charities including unregistered charities; </p>
        <p align="left">2. trusts (unless they are established in Canada, EEA, Switzerland, Australia, New Zealand or the United States); </p>
        <p align="left">3. bearer shares or entities having bearer share company in their ownership chain; </p>
        <p align="left">4. anonymous accounts; </p>
        <p align="left">5. money service businesses or any business that carries on the activity of: </p>
        <p align="left" class="use-two-title">i. cashing cheques which are made payable to customers; </p>
        <p align="left" class="use-two-title">ii. private-to-private money remittance involving cash; </p>
        <p align="left">6. banks that do not have a physical presence in any country (i.e., “shell banks”); </p>
        <p align="left">7. other categories outside of our risk appetite in accordance with our internal policies, our banking partners’ policies or the policies of participants in our payment network. <b /></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>5. Country of Restriction </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">1. Any party based in the countries listed below will be rejected. The country is determined by the country of residence for the individuals and the jurisdiction of registration for companies involved in the transaction. </p>
        <p align="left">2. Payments to / from countries below will be rejected. Iran North Korea </p>
        <p align="left" />
        <p align="left">3. Payment to countries listed below will be subjected to further CDD or transaction monitoring procedures to make sure transaction and purpose of the transaction are compliant, legal and not involved with any sanction listed occasions. {{ themeData.saasMerNameEn }} will request further information or documents to undertake enhance due diligence (<b>EDD</b>) procedures which may result in directly refusing to accept/make payments from/to partner/clients running business located in these areas: </p>
        <p align="left" class="use-two-title">i. Countries defined by FATF as Uncooperative / AML Deficient </p>
        <h1 style="page-break-before:always; " />
        <p align="left" class="use-two-title">ii. Countries/entities where International sanctions are in place including but not limited to the following: </p>
        <p align="left" class="use-three-title">(a) OFAC of USA </p>
        <p align="left" class="use-three-title">(b) United Nation </p>
        <p align="left" class="use-three-title">(c) HMT of United kingdom </p>
        <p align="left" class="use-three-title">(d) European Union </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>6. You also agree: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">1. NOT to copy or use any part of our Services in contravention of the provisions of our Client Agreement; and </p>
        <p align="left">2. NOT to access without authority, interfere with, damage or disrupt: </p>
        <p align="left" class="use-two-title">i. any part of our Services; </p>
        <p align="left" class="use-two-title">ii. any equipment or network on which our Website is stored; </p>
        <p align="left" class="use-two-title">iii. any software used in the provision of our Services; or </p>
        <p align="left" class="use-two-title">iv. any equipment or network or software owned or used by any third party. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>7. You may only use your Global Account number (as we provided to you) to receive funds for the following purposes: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">1. receiving payouts from an approved e-commerce and marketplace platforms; </p>
        <p align="left">2. receiving payments from your clients and other third parties for the purpose of business payments; </p>
        <p align="left">3. receiving your own funds from other legitimate business sources. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>8. You may only fund payments from a bank account in your own name to the account number we provide to you in connection with the Payment Services </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>9. You must not use our Services for the following purposes: </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left">1. receiving payouts or withdrawals from electronic money platforms/services/providers unless its business background has been approved by certain correspondent banks, regulators, and / or partners of {{ themeData.saasMerNameEn }} ; </p>
        <p align="left">2. receiving payouts from short term lenders; </p>
        <p align="left">3. unless we approve, conducting payouts or collecting funds on behalf of any other person or entity other than yourself, including any sister entities or parent company; </p>
        <p align="left">4. setting up direct debits on your Global Account; </p>
        <p align="left">5. conducting your business or using the Services in a manner that is likely to result in complaints, disputes, reversals, chargebacks or other liability to {{ themeData.saasMerNameEn }}, other customers of {{ themeData.saasMerNameEn }}, third parties or you. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>9. Content standards </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left">These content standards apply to any material you contribute to our Services. These contributions must: </p>
        <p align="left">1. be accurate; </p>
        <p align="left">2. be genuinely held (where they state opinions); and </p>
        <p align="left">3. comply with applicable laws in the Hong Kong, China, Canada and in any country from which they are posted or to which they relate. 4. These contributions must not: </p>
        <p align="left" class="use-two-title">i. contain any material which is defamatory; </p>
        <p align="left" class="use-two-title">ii. contain any material which is obscene, offensive, hateful or inflammatory; </p>
        <p align="left" class="use-two-title">iii. promote sexually explicit material; </p>
        <p align="left" class="use-two-title">iv. promote violence; </p>
        <h1 style="page-break-before:always; " />
        <p align="left" class="use-two-title">v. promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; </p>
        <p align="left" class="use-two-title">vi. infringe any copyright, database right or trademark of any other person; </p>
        <p align="left" class="use-two-title">vii. be likely to deceive any person; </p>
        <p align="left" class="use-two-title">viii. be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence; </p>
        <p align="left" class="use-two-title">ix. promote any illegal activity; </p>
        <p align="left" class="use-two-title">x. be threatening in any way, abusive or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety; </p>
        <p align="left" class="use-two-title">xi. be likely to harass, upset, embarrass, alarm or annoy any other person; </p>
        <p align="left" class="use-two-title">xii. be used to impersonate any person, or to misrepresent your identity or affiliation </p>
        <p align="left" class="use-two-title">with any person; </p>
        <p align="left" class="use-two-title">xiii. give the impression that they relate to us, if this is not the case; or </p>
        <p align="left" class="use-two-title">xiv. advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>10. Transaction limits </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left">Unless you have received approval for a higher limit, you can only make a transaction involving a foreign currency conversion where your open positions or unsettled transactions do not exceed USD 10,000 (or its equivalent) at the time of requesting the transaction with us. </p>
        <p align="left">We may review our internal credit risk policies from time to time and adjust our transaction limits in line with those policies. If we lower the transaction limit, we will give you reasonable notice of such change. </p>
        <p align="left" />
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>12. Suspension and termination </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">We alone will determine whether there has been a breach of this acceptable use policy through your use of our Services. </p>
        <p align="left">We take breach of this policy seriously and may take the following actions: </p>
        <p align="left" />
        <p align="left">1. immediate, temporary or permanent withdrawal of your right to use our Services </p>
        <p align="left">2. suspend or cancel your payment orders and take such other actions as we consider necessary; </p>
        <p align="left">3. immediate, temporary or permanent removal of any posting or material uploaded by you; </p>
        <p align="left">4. issue of a warning; </p>
        <p align="left">5. take legal action against you including proceedings for reimbursement of all costs on an “all expenses” basis; and/or </p>
        <p align="left">6. reporting and disclosure of information to law enforcement authorities. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>13. Other policies </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left">This policy does not limit any of our rights in our agreements with you or the implementation of our risk appetite in accordance with our internal policies, our banking partners’ policies or the policies of participants in our payment network. </p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p class="navigationTitle" align="left"><b>14. Changes to the acceptable use policy </b></p>
        <p align="left" style="line-height: 100%; page-break-inside: auto; orphans: 2; widows: 2; margin-bottom: 3mm; background: #ffffff; page-break-before: auto; page-break-after: auto"><br></p>
        <p align="left" />
        <p align="left">We may revise this acceptable use policy at any time by amending this page without prior notification. You will need to check it regularly as it is legally binding on you. </p>

      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'UsePolicyEn',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle {
  font-size: 15px;
  color: black;
  font-weight: bold;
}
p.use-two-title {
  margin-left: 20px;
}
p.use-three-title {
  margin-left: 40px;
}
</style>
